<template>
    <div class="contentDetail">
        <qy-top-nav title="代理商申请" />
        <div style="padding:0 10px;background-color: #fff;"  v-html="content" :style="{height:containerHeight,overflow:'auto'}"></div>
		<div v-show="!!rejectMsg" style="font-size: 12px;margin:2px 0;color:red;padding-left: 20px;font-weight: bold;">
			<van-icon name="warning-o" color="red" style="position: relative;top:2px;margin-right: 4px;"/><span>{{rejectMsg}}</span>
		</div>
		<div v-show="showApplyBtn" style="padding:5px 20px;">
			<van-button type="warning" color="#ffad1a" block @click="submitApplyInfo">{{applyBtnText}}</van-button>
		</div>
    </div>
</template>
<script type="text/javascript">
import request from '@/utils/request';
export default {
    name:"content",
    data() {
        return {
            title: "",
            content: "",
            noNav:true,
            containerHeight: 0,
            rejectMsg: "",
			applyBtnText:"申请成为代理商",
			showApplyBtn:false,
			applyAble:false
        }
    },
    created() {
        this.containerHeight = document.documentElement.clientHeight - 120 + "px";
        let code = this.getUrlParam("code")||"-1";
        if(code==-1){
            code="xI3GkW16dpoThKl";
        }else{
            this.noNav=false;
        }
        request.post("/api/page", { code: code }).then(rs => {
            if (rs.success) {

                this.title = rs.data.title;
                this.content = rs.data.content;
            } else {
                this.Toast(rs.msg);
            }
        });
		this.fetchApplyInfo();
    },
    methods: {
		fetchApplyInfo(){
			this.doPost("/api/proxyApply/",(rs)=>{
				if(rs.data){
					if(rs.data.status==0){
						this.applyAble=false;
						this.applyBtnText="审核中";
					}else if(rs.data.status==1){
						this.applyAble=false;
						this.applyBtnText="审核通过";
					}else{
						this.applyAble=true;
						this.rejectMsg=rs.data.auditMsg;
					}
				}else{
					this.applyAble=true;
				}
				this.showApplyBtn=true;
			});
		},
		submitApplyInfo(){
			if(!this.applyAble){
				return;
			}
			this.confirm("确定要申请成为代理商吗?",()=>{
				
				this.doPost("/api/proxyApply/add",(rs)=>{
					if(rs.success){
						this.toast("申请已提交,请耐心等待!",()=>{
							this.navigateBack();
						});
					}else{
						this.Toast(rs.msg)
					}
				})
			});
		}
    }
}
</script>
<style>
.contentDetail img{
    max-width: 100%;
}
</style>